import React, { useEffect, useRef, useContext, useState } from "react";
import PhaserGame from '../FallingNut/FallingBut'; // Путь к вашему классу PhaserGame
import cls from './HomeContent.module.css';
import Count from "../count/Count";
import NutMain from "../MainNut/Nut";
import { Context } from '../../index.js'
import { observer } from "mobx-react-lite";
import { useTranslation } from 'react-i18next';
import { updateAction } from "../../http/userAPI.js";

const HomeContent = observer(() => {
  let cicleTime = 10;
  const { user } = useContext(Context);
  const [time, setTime] = useState(0);
  const [timeParse, setTimeParse] = useState('00:00:00');
  const { t } = useTranslation();
  const canvasRef = useRef(null);
  const vibrationIntervalRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current) {
      new PhaserGame(canvasRef.current, {
        backgroundColor: "#028af8",
        user: user,
      });
    }
  }, [user]);

  useEffect(() => {
    setTime(user.timeAction);
    setTimeParse(parseTime(user.timeAction));
  }, [user.timeAction]);

  const parseTime = (timeStart) => {
    const time = cicleTime - timeStart;
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    if (time >= cicleTime) {
      clearInterval(vibrationIntervalRef.current);
      vibrationIntervalRef.current = setInterval(() => {
        window?.Telegram?.WebApp.HapticFeedback.impactOccurred('medium');
      }, 10000);
    } else {
      clearInterval(vibrationIntervalRef.current);
      const interval = setInterval(() => {
        setActions();
      }, 1000);
      return () => clearInterval(interval);
    }

    return () => clearInterval(vibrationIntervalRef.current);
  }, [time]);
  useEffect(() => {
    window.history.pushState({ some: 'state' }, '', '/home');
  }, [])

  const setActions = () => {
    if (time >= cicleTime) {
      return;
    }
    setTime(prevTime => {
      const newTime = prevTime + 1;
      setTimeParse(parseTime(newTime));
      return newTime;
    });
  };

  function getCoinsParse(coins) {
    const coinsParse = coins.toString();
    let answer = '';
    let counter = 0;

    for (let i = coinsParse.length - 1; i >= 0; i--) {
        answer = coinsParse[i] + answer; 
        counter++;

        if (counter % 3 === 0 && i !== 0) {
            answer = ' ' + answer;
        }
    }

    return answer;
}


  return (
    <div className={cls.background}>
      <div className={cls.countContainer}>
        <Count count={getCoinsParse(user.coins)}></Count>
        <h2 className={cls.subheader}>{user.username}</h2>
      </div>

      <NutMain></NutMain>

      <div className={cls.downCont}>
        <div className={cls.canvasCont}>
          <PhaserGame className={cls.canvas}></PhaserGame>
        </div>
        {
          time >= cicleTime ?
            <div className={cls.shakeCont}>
              <div className={cls.shakeButton}
                onClick={async () => {
                  try {
                    user.setIsAction(true);
                    await updateAction(user.id);
                    setTime(0);
                    user.setAction(0);
                  } catch (e) {
                    console.log(e);
                  }

                }}>{t('Shake')}</div>

              <div className={cls.shakeBack}></div>
            </div>

            : <div className={cls.timeCont}>
              <div className={cls.timeButton} style={{ opacity: 0.75 }}
              >{timeParse}</div>
            </div>
        }

        <div className={cls.downBanner}>
          <ul className={cls.list}>
            <li>
              <span>{t('mainPageDescriptionFirst')}</span>
            </li>
            <li>
              <span>{t('mainPageDescriptionSecond')}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
});

export default HomeContent;
