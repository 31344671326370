import React, { useEffect } from "react";
import { BrowserRouter, useNavigate  } from "react-router-dom";


import "./App.css";
import AppRouter from "./components/AppRouter";
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n';
import { AliveScope } from 'react-activation';
const tg = window?.Telegram?.WebApp;

function App() {

  if (tg) {
      tg.expand();
      tg.onEvent('backButtonClicked', () => {
        alert('test');
      });
      tg.setBottomBarColor("#467A43");
      tg.setHeaderColor('#467A43')
  }

  return (
    <I18nextProvider i18n={i18n}>
      <AliveScope>
      <BrowserRouter>
        <AppRouter />
    
      </BrowserRouter>
      </AliveScope>
    </I18nextProvider>
    
  );
}

export default App;
